<template>
    <div class="animate__animated animate__fadeIn">
        <div class="disable-select">
            <div>
                <div class="dead-link">
                    {{$t("page-404.dead-link")}}
                </div>
                <div class="use-navi">
                    {{$t("page-404.use-navi")}}
                </div>
            </div>
            <img class="fill-height-and-width"
                 :src="link"
                 alt=""
                 loading="lazy"><!--@click="choose_link"-->
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                link : "/assets/img/link_green.webp",
                links: [
                    "/assets/img/link/link_green.webp",
                    "/assets/img/link/link_red.webp",
                    "/assets/img/link/link_blue.webp",
                    "/assets/img/link/link_purple.webp",
                    "/assets/img/link/link_dark.webp"
                ]
            }
        },
        created() {
            this.choose_link();
        },
        methods: {
            choose_link() {
                let random = Math.floor(Math.random() * 5);
                this.link  = this.links[random]
            }
        },
        metaInfo() {
            return {
                title: `${this.$t('meta.page-title-unformated')} - ${this.$t('meta.page-title-404')}`,
                meta : [{
                    name   : "description",
                    content: this.$t("meta.description"),
                }]
            }
        },
    }
</script>